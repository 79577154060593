$(document).ready(function () {
	// Hijack cta blocks that use hubspot to inject the anchor links

	$('.hs-cta-wrapper')
		.find('a.cta_button')
		.each(function () {
			$(this).attr('data-open-demo-modal', '');
		});

	let closeBtn = $('[data-demo-modal-close-btn]'),
		modal = $('[data-demo-modal]');

	const openModal = $('[data-open-demo-modal]');

	closeBtn.on('click', function () {
		modal.removeClass('is-active');
	});

	openModal.on('click', function (e) {
		e.preventDefault();
		modal.addClass('is-active');
	});

	let firstname, company;

    hbspt.forms.create({
		region: 'na1',
		portalId: '2611577',
		formId: '033f6716-7a03-45dc-91c2-15d340aada60',
		target: '[data-demo-modal-form]',
		onFormSubmit: function () {
			firstname = $('[data-demo-modal-form] input[name="firstname"]').val();
			lastname = $('[data-demo-modal-form] input[name="lastname"]').val();
			company = $('[data-demo-modal-form] input[name="company"]').val();
			email = $('[data-demo-modal-form] input[name="email"]').val();
		},
		onFormSubmitted: function ($form) {
			$('.c-demo-modal__form-headline').hide();

			if (company.length > 1) {
				window.location.href =
					'https://ad.chargebacks911.com/roi-calculator?firstname=' +
					firstname +
					'&lastname=' +
					lastname +
					'&company=' +
					company +
					'&email=' +
					email;
			} else {
				window.location.href =
					'https://ad.chargebacks911.com/roi-calculator?firstname=' +
					firstname +
					'&lastname=' +
					lastname +
					'&email=' +
					email;
			}
		},
	});

	$('body').on('mousedown', function(e){
	
			if( $(window).width() > 991 && !e.target.closest(".c-demo-modal__form") ) {
				modal.removeClass('is-active');
			}
	
	});
});