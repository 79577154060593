$(document).ready(function () {
    var contactFormDiv = $("#contactForm2");
    
    if (contactFormDiv.length) {
        hbspt.forms.create({
            region: "na1",
            portalId: "2611577",
            formId: "ef44fcc2-6d45-4615-8956-da9237994f42",
            target: "#contactForm2"
        });
    }
});