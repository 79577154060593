// $(document).ready(function () {
// 	const switcher = $('[data-language-switcher]');
// 	const button = $('[data-language-switcher-button]');
// 	const items = $('[data-language-switcher-items]');
// 	const item = items.find('[data-language]');
// 	const active = $('[data-language-switcher-active] span');
// 	let language = '';

// 	const updateLanguage = () => {
// 		const currentUrl = window.location.pathname;
// 		const isMandarin = currentUrl.startsWith('/cn/');

// 		active.text(isMandarin ? '中文' : 'English');

// 		updateLinks(isMandarin);
// 	};

// 	const updateLinks = (isMandarin) => {
// 		$('a[href]').not('.c-footer a, #hpBanner a').each(function () {
// 			const href = $(this).attr('href');
// 			const baseHref = href.replace(/^\/cn\//, '/');
// 			const newHref = isMandarin ? `/cn${baseHref}` : baseHref;
// 			$(this).attr('href', newHref);
// 		});
// 	};

// 	updateLanguage();

// 	button.on('click', (event) => {
// 		event.stopPropagation();
// 		switcher.toggleClass('--is-toggled');
// 	});

// 	items.on('click', (event) => {
// 		event.stopPropagation();
// 	});

// 	item.on('click', function () {
// 		const language = $(this).data('language');
// 		const currentUrl = window.location.pathname.replace(/^\/cn\//, '/');
// 		const newUrl = language === 'mandarin' ? `/cn${currentUrl}` : currentUrl;

// 		window.location.href = newUrl;

// 		switcher.removeClass('--is-toggled');
// 	});
// });

$(document).ready(function () {
	const switcher = $('[data-language-switcher]');
	const button = $('[data-language-switcher-button]');
	const items = $('[data-language-switcher-items]');
	const item = items.find('[data-language]');
	const active = $('[data-language-switcher-active] span');

	const englishDomain = 'https://apac.chargebacks911.com'; // English site
	const mandarinDomain = 'https://chargebacks911.com.cn'; // Mandarin site
	const alternateMandarinDomain = 'https://apac.chargebacks911.com.cn'; // Alternate Mandarin site

	// Check if the current hostname matches any Mandarin domains
	const isMandarin = window.location.hostname.endsWith('.com.cn') || window.location.hostname === 'apac.chargebacks911.com.cn';

	active.text(isMandarin ? '中文' : 'English'); // Set initial active language

	button.on('click', (event) => {
		event.stopPropagation();
		switcher.toggleClass('--is-toggled');
	});

	items.on('click', (event) => {
		event.stopPropagation();
	});

	item.on('click', function () {
		const language = $(this).data('language');
		const currentUrl = window.location.pathname;

		// Check if the current language is the same as the selected one
		if ((language === 'mandarin' && isMandarin) || (language !== 'mandarin' && !isMandarin)) {
			// If the current language matches the selected one, do nothing
			return;
		}

		// Switch domains and reload if different language is selected
		const newUrl = language === 'mandarin' ? `${mandarinDomain}${currentUrl}` : `${englishDomain}${currentUrl}`;

		// For alternate Mandarin domain, handle separately if needed
		if (!isMandarin && window.location.hostname === 'apac.chargebacks911.com.cn') {
			window.location.href = `${alternateMandarinDomain}${currentUrl}`;
		} else {
			window.location.href = newUrl;
		}

		switcher.removeClass('--is-toggled');
	});
});